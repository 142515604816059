/**
 * Suppress warnings for `dangerouslySetInnerHTML`.
 * HTML is generated by 'gatsby-transformer-remark'
 */
/* eslint-disable react/no-danger */
/* eslint-disable react/function-component-definition */
// eslint-disable-next-line react/forbid-prop-types
import React from 'react'
import {graphql} from 'gatsby'
import PropTypes from 'prop-types'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import Typography from 'components/Typography'
import ButtonIconMove from 'components/ButtonIconMove'
import ButtonGroup from 'components/ButtonGroup'
import Cta from 'components/Cta'
import InfoImgList from 'components/InfoImgList'
import RedirectLink from 'components/RedirectLink'
import BillboardImageInfoTwo from '../../components/BillboardImageInfoTwo'

import '../../css/main.scss'

import * as styles from './styles.module.scss'
import useUtagView from '../../hooks/useUtagView'

const AdditionalCoveragesTemplate = ({data, pageContext, location}) => {
    useUtagView('quebec_add_ons', 'add ons');
    return (
        <>
            <SEO
                title={data.pageData.frontmatter.metaTitle}
                description={`${data.pageData.frontmatter.metaDescription}`}
            />
            <Layout
                mainClass="home-page"
                headerBtn={data.pageData.frontmatter.headerBtn}
                headerBtnHref={data.pageData.frontmatter.promoHref}
                pathname={location.pathname}
                langCode={pageContext.lang}
            >
                <BillboardImageInfoTwo
                    img={data.pageData.frontmatter.promoImage}
                    direction="reverse"
                    className="t-l t-l-m t-s-s b-xl"
                >
                    <Typography variant="h1" isDisplay className="t-m">
                        {data.pageData.frontmatter.promoTitle}
                    </Typography>
                    <Typography variant="txt1" isLead>
                        {data.pageData.frontmatter.promoText}
                    </Typography>
                    <ButtonGroup>
                        <div className="btn-group__item">
                            <ButtonIconMove variant="primary" size="l" sizeResponsive={['m-l', 's-s']} external href={data.pageData.frontmatter.promoHref}>
                                {data.pageData.frontmatter.promoBtn}
                            </ButtonIconMove>
                        </div>
                    </ButtonGroup>
                </BillboardImageInfoTwo>
                <div className="center col-12 t-l b-l">
                    <div className="center col-r-8-12 col-l-fixed-8 col-m-fw">
                        <InfoImgList content={data.infoImgListContent.edges}/>
                    </div>
                </div>
                <Cta
                    title={data.pageData.frontmatter.ctaTitle}
                    text={data.pageData.frontmatter.ctaSubtitle}
                    className="t-l t-s-l b-l b-s-l"
                    content={data.ctaContent.edges}
                />
                <div className="center col-12">
                    <RedirectLink
                        text={data.pageData.frontmatter.refer}
                        link="https://www.hagerty.ca/"
                        linkText={data.pageData.frontmatter.referBtn}
                        className="t-l b-m"
                    />
                </div>
                <div className="t-l b-l">
                    <div className="center col-12 ta-l">
                        <div className="center col-r-8-12 col-l-fixed-8 col-m-fw txt-4 c-dark-2">
                            <Typography variant="txt4" className="c-dark-2" dangerouslySetInnerHTML={{ __html: data.pageData.frontmatter.noteOne }}/>
                            <Typography variant="txt4" className="c-dark-2">
                                {data.pageData.frontmatter.noteTwo}
                            </Typography>
                            <Typography variant="txt4" className="c-dark-2">
                                {data.pageData.frontmatter.noteFree}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export const query = graphql`
  query (
  $absolutePath: String!
  $ctaPath: String!
  $infoImgListPath: String!
  ) {
    pageData: markdownRemark(fileAbsolutePath: { regex: $absolutePath }) {
      frontmatter {
        headerBtn
        metaTitle
        metaDescription
        promoTitle
        promoText
        promoBtn
        promoHref
        promoImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        ctaTitle
        ctaSubtitle
        refer
        referBtn
        noteOne
        noteTwo
        noteFree
      }
      html
    }
    ctaContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $ctaPath } }
      sort: { fields: frontmatter___id }
    ) {
      edges {
        node {
          frontmatter {
            id
            title
            imageAlt
            icon
            text
            btnText
            btnIcon
            href
          }
        }
      }
    }
    infoImgListContent: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $infoImgListPath } }
      sort: { fields: frontmatter___id }
    ) 
    {
      edges {
        node {
          frontmatter {
            id
            title
            text
            alt
            img {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

AdditionalCoveragesTemplate.propTypes = {
    pageContext: PropTypes.object,
    location: PropTypes.object,
    data: PropTypes.exact({
        pageData: PropTypes.exact({
            frontmatter: PropTypes.exact({
                headerBtn: PropTypes.string,
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
                promoTitle: PropTypes.string,
                promoText: PropTypes.string,
                promoBtn: PropTypes.string,
                promoHref: PropTypes.string,
                promoImage: PropTypes.any,
                ctaTitle: PropTypes.string,
                ctaSubtitle: PropTypes.string,
                refer: PropTypes.string,
                referBtn: PropTypes.string,
                noteOne: PropTypes.string,
                noteTwo: PropTypes.string,
                noteFree: PropTypes.string,
            }),
            html: PropTypes.any,
        }),
        infoImgListContent: PropTypes.exact({
            edges: PropTypes.array,
        }),
        ctaContent: PropTypes.exact({
            edges: PropTypes.array,
        }),
    }).isRequired,
}

export default AdditionalCoveragesTemplate
