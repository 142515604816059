import React from 'react'
import PropTypes from 'prop-types'
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import clsx from "clsx";
import Typography from '../Typography'
import * as styles from './InfoImgList.module.scss'


const InfoImgList = (props) => {
    const {content} = props;
    return (
        content.map((item) => {
            return (
                <div key={item.node.frontmatter.id} className={styles.wrapper}>
                    <div className={clsx(styles.info, `b-l`)}>
                        <Typography variant="h3" dangerouslySetInnerHTML={{ __html: item.node.frontmatter.title }}/>
                        <Typography variant="txt2" className="c-dark-2" dangerouslySetInnerHTML={{ __html: item.node.frontmatter.text }}/>
                    </div>
                    <GatsbyImage
                        className={styles.imgWrapper}
                        image={getImage(item.node.frontmatter.img)}
                        alt={item.node.frontmatter.alt}
                        objectFit="contain"
                    />
                </div>
            )
        })
    )
}

InfoImgList.propTypes = {
    content: PropTypes.array,
}

export default InfoImgList

